"use strict";
require.config({
    baseUrl: "/resources/𝓥𝓔𝓡𝓢𝓘𝓞𝓝/",
    paths: {
        "cus_container": "vendor/composer/cutiundstier/cus-container/Resources/Public/JavaScript",
        "cus_elements": "vendor/composer/cutiundstier/cus-elements/Resources/Public/JavaScript",
        "cus_jobs": "vendor/composer/cutiundstier/cus-jobs/Resources/Public/JavaScript",
        "cus_news": "vendor/composer/cutiundstier/cus-news/Resources/Public/JavaScript",
        "cus_verein": "vendor/composer/cutiundstier/cus-verein/Resources/Public/JavaScript",
        "cus_sitepackage": "vendor/composer/cutiundstier/cus-sitepackage/Resources/Public/JavaScript",
        // libraries
        "@sentry/browser": "vendor/@sentry/browser/build/bundle.min",
        "async": "vendor/requirejs-plugins/src/async",
        "bootstrap": "vendor/bootstrap/dist/js/bootstrap.bundle.min",
        "datatables": "vendor/datatables/media/js/jquery.dataTables",
        "flipbook": "typo3conf/ext/cus_elements/Resources/Public/Vendor/Flipbook/js/flipbook",
        "glightbox": "vendor/glightbox/dist/js/glightbox.min",
        "iscroll": "vendor/iscroll/build/iscroll-zoom",
        "jquery": "vendor/jquery/dist/jquery.min",
        "pdfjs-dist": "vendor/pdfjs-dist",
        "slick": "vendor/slick-carousel/slick/slick.min",
        "swiper": "vendor/swiper/swiper-bundle.min",
        "three": "vendor/three/build/three.min",
        "web-vitals": "vendor/web-vitals/dist/web-vitals",
    },
    map: {
        "*": {
            "configuration": "public/vendor/composer/cutiundstier/cus-sitepackage/Script/configuration/configuration",
            "web-vitals/base": "vendor/web-vitals/dist/web-vitals.base.umd"
        },
    },
    shim: {
        "@sentry/browser": {
            exports: "Sentry"
        },
        "jquery-waypoints": {
            deps: ["jquery"],
            exports: "window.Waypoint",
        },
        "bootstrap": {
            deps: ["jquery"],
            exports: "$.fn.popover", //Use any plugin as indicator for RequireJS (see https://stackoverflow.com/a/13556882/6787528)
        },
        "flipbook": {
            deps: ["jquery", "pdfjs-dist/build/pdf", "pdfjs-dist/build/pdf.worker", "iscroll", "three"],
            exports: "$.fn.flipBook"
        },
        "slick": {
            deps: ["jquery"],
            exports: '$.fn.slick'
        }
    },
    waitSeconds: 0,
    enforceDefine: false,
});
require(['jquery'], function ($) {
    require(['cus_sitepackage/Typescript/main/require-initial-dependencies']);
});
